<template>
  <div class="wh-space-bottom">
    <b-card>
      <edit-field-header
        :title="title"
        @routerBack="$router.back()"
      />

      <edit-field
        :is-edit="true"
        :fields="fields"
        :row-data="row_data"
        post-api="/kiosk/WeHealth/UpdateKioskPeripheralModel"
        finish-router="kioskPeripheralModel"
        permission=""
      >
      </edit-field>
    </b-card>
  </div>
</template>

<script>
import fields, { reloadFieldData } from './fields'
import EditField from '@/components/EditField2'
import EditFieldHeader from '@/components/EditFieldHeader'
import common from '@/common'
import { flattenObject, expandObject } from '@/libs/ez-utils'

export default {
  name: 'Edit',
  components: {
    EditFieldHeader,
    EditField,
    fields,
  },

  data() {
    return {
      title: common.getMenuName('kioskPeripheralModel') + ' - ' + common.getI18n('common.edit'),
      id: this.$router.currentRoute.params.id,
      fields,
      row_data: {
        purposes: [],
      },
    }
  },

  created() {
    reloadFieldData()
  },

  mounted() {
    this.id = common.decrypt(this.id)
    if (this.id === undefined) {
      this.$router.replace({ path: '/' + this.$route.path.split('/')[1] })
      return false
    }
    common.apiGetData('/kiosk/WeHealth/GetKioskPeripheralModel?modelTag=' + this.id)
      .then(res => {
        this.row_data = res.data.model
      })
  },

  methods: {},
}
</script>

<style scoped>

</style>
